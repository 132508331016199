body {
    @include mq-1280 {
        background-color: $color-gray-11;
    }
}
#wrap {
    max-width: 1280px;
    min-width: 318px;
    margin: auto;
}

#puiWrapper {
    padding-bottom: 0 !important;
}

#dealPrdWrap,
#recopickPrdWrap,
#commonBillWrap,
#recommendPrdWrap {
    display: none;
}

#homeBillWrap {
    margin-bottom: 0;
}

.m_nav {
    position: relative;
    width: 100%;
    max-width: 1280px;
    background: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .c-badge-beta {
        @include get-sprite-common("beta");
        overflow: hidden;
        font-size: 1px;
        color: transparent;
        vertical-align: 2px;
        margin-left: 2px;
    }
    .nav {
        overflow: hidden;
        position: relative;
        margin-right: 0;
        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 0;
            width: 48px;
            height: 43px;
            z-index: 3;
        }
        &:before {
            display: none;
            background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
            left: 0;
        }
        &:after {
            display: block;
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
            right: 0;
        }
        &.show-gradation {
            &:before {
                display: block;
            }
        }

        ul {
            @include size(100% 43px);

            display: table;
            position: relative;
            z-index: 2;
        }

        li {
            display: table-cell;

            &:first-child {
                padding-left: 10px;
            }
            &:last-child {
                padding-right: 45px;
            }
        }

        &__wrap {
            &--snap {
                overflow-x: auto;
                scroll-snap-type: x mandatory;
                -webkit-overflow-scrolling: touch;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }
    }

    li {
        height: 43px;
        color: #333;
        font-size: 17px;
        font-weight: bold;
        text-align: center;
        white-space: nowrap;

        a {
            display: block;
            position: relative;
            padding: 2px 10px;
            color: #333;
            line-height: 39px;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

            &:after {
                @include size(100% 2px);

                position: absolute;
                left: 0;
                bottom: 0;
                background: transparent;
                -webkit-transform: scaleY(0.1);
                transform: scaleY(0.1);
                content: "";
            }
        }

        &.amazon {
            a {
                .new {
                    background-color: $color-amazon-bluegreen;
                }
            }
        }

        &.on {
            a {
                color: $color-11st-red;

                &:after {
                    background: $color-11st-red;
                    -webkit-transform: scaleY(1);
                    transform: scaleY(1);
                    -webkit-transition: 0.5s;
                    transition: 0.5s;
                }
            }
            &.amazon {
                a {
                    color: $color-amazon-bluegreen;
                    &::after {
                        background-color: $color-amazon-bluegreen;
                    }
                    .new {
                        background-color: $color-amazon-bluegreen;
                    }
                }
            }
            .c-badge-beta {
                @include get-sprite-position-common("beta_active");
            }
        }

        .new {
            @include size(4px 4px);

            @include border-radius(2px);

            overflow: hidden;
            position: absolute;
            top: 10px;
            margin-left: 4px;
            background: $color-11st-red;
            color: transparent;
            font-size: 2px;
        }
    }

    .btn_toggle {
        @include sprite-retina($sp_eui_tab_open);
        overflow: hidden;
        position: absolute;
        color: transparent;
        z-index: 3;
        outline: none;
        top: 3px;
        right: 8px;
        border: 0;
        background-color: transparent;
    }

    &.open .btn_toggle {
        @include sprite-retina($sp_eui_tab_close);
    }

    .edit {
        display: none;
        position: absolute;
        top: 44px;
        left: 0;
        width: 100%;
        z-index: 2;

        ul {
            background: #fff;

            &:after {
                content: "";
                display: block;
                clear: both;
            }
        }

        li {
            float: left;
            width: 33.33%;
            margin-right: -1px;
            border-right: 1px solid #f4f4f4;
            border-bottom: 1px solid #f4f4f4;

            &:nth-child(3n) {
                border-right: none;
            }

            a {
                overflow: hidden;
                position: relative;
                font-weight: normal;
                color: #111;

                span:not(.new) {
                    position: absolute;
                    left: 8px;
                    top: 2px;
                    font-size: 12px;
                }
            }

            &.on a {
                color: $color-11st-red;

                &:after {
                    display: none;
                }
            }
        }

        .txt {
            @include vertical-middle(43px);

            display: none;
            position: absolute;
            top: -44px;
            left: 0;
            right: 0;
            padding: 0 16px;
            background: #fff;
            color: #111;
            font-size: 15px;
        }

        .btn {
            padding: 4px 8px;
            background: #f9f9f9;
            text-align: right;

            & + .btn {
                display: none;
            }
        }
    }

    .btn_edit {
        height: 32px;
        padding: 0 8px;
        border: none;
        background: none;
        color: #333;
        font-size: 14px;

        &:before {
            @include size(18px 15px);

            display: inline-block;
            margin-right: 8px;
            background: url("/MW/img/main/ico_edit.png") no-repeat 0 0;
            background-size: 100% 100%;
            vertical-align: -2px;
            content: "";
        }
    }

    & .btn_apply,
    & .btn_cancel {
        @include size(56px 32px);

        @include border-radius(2px);

        padding: 0 8px;
        margin-left: 1px;
        font-size: 14px;
    }

    .btn_apply {
        border: none;
        background: #444;
        color: #fff;
    }

    .btn_cancel {
        border: 1px solid #ccc;
        background: #fff;
        color: #111;
    }

    .draggable {
        .txt {
            display: block;
        }

        .btn {
            display: none;

            & + .btn {
                display: block;
            }
        }

        li {
            a {
                margin: 8px;
                border: 1px dashed #ccc;
                line-height: 21px;
            }

            &:first-child a,
            &.draggable-skip a {
                border-color: transparent;
                color: #ccc;
            }

            &.draggable-start a {
                height: 21px;
                border: 1px solid $color-11st-red;
                color: $color-11st-red;
            }

            &.draggable-clone {
                background: rgba(255, 255, 255, 0.7);
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

                a {
                    border-color: transparent;
                }
            }
        }

        .drag_start li a {
            color: #999;
        }

        .new {
            display: none;
        }
    }

    &.mwUI_is_fixed {
        @include translateZ(0);

        position: fixed;
        top: 0;
        z-index: 97;
    }

    &.mwUI_is_sticky {
        position: -webkit-sticky;
        position: -moz-sticky;
        position: -ms-sticky;
        position: -o-sticky;
        position: sticky;
        top: 0;
        z-index: 97;
    }

    &.open {
        z-index: 102;

        .edit,
        .navigation-list {
            display: block;
        }
    }

    .layer_notification {
        position: absolute;
        left: 19%;
        background: #5676da;
        display: inline-block;
        font-size: 12px;
        height: 28px;
        line-height: 28px;
        color: #fff;
        padding: 0 14px;
        border-radius: 14px;
        letter-spacing: -0.5px;
        z-index: 2;
        &:after {
            height: 0;
            width: 0;
            content: "";
            display: block;
            position: absolute;
            top: -7px;
            left: 45%;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 7px solid #5676da;
            z-index: 1;
        }
        &.order4 {
            left: 37%;
        }
        .noti_text {
            font-weight: 300;
            strong {
                font-weight: 500;
            }
        }
        &.tooltip-in {
            -webkit-animation: tooltip-in ease-in 0.5s;
            animation: tooltip-in ease-in 0.5s;
            -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
        }
        &.tooltip-out {
            -webkit-animation: tooltip-out ease-out 0.5s;
            animation: tooltip-out ease-out 0.5s;
            -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
        }
    }

    .navigation-list {
        display: none;
        position: absolute;
        top: 44px;
        left: 0;
        width: 100%;
        padding: 16px 16px 8px 16px;
        z-index: 2;
        box-sizing: border-box;
        background-color: $color-gray-14;
        h2 {
            font-size: $font-size-body1;
            color: $color-11st-red;
            padding-bottom: 12px;
            &.amazon {
                color: $color-amazon-bluegreen;
            }
        }
        .c-tag-box {
            .c-tag {
                margin: 0 6px 8px 0;
                min-width: 43px;
                text-align: center;
                .c-badge-beta {
                    vertical-align: 0;
                }
                &--selected {
                    background-color: transparent;
                    border-color: $color-11st-red;
                    font-weight: bold;
                    .c-badge-beta {
                        @include get-sprite-position-common("beta_active");
                    }
                }
            }
            .c-badge-new {
                @include size(3px);
                @include border-radius(100%);
                display: inline-block;
                overflow: hidden;
                margin: 7px -2px 0 3px;
                background: $color-11st-red;
                font-size: 2px;
                color: transparent;
                vertical-align: top;
            }
        }
        & > .amazon + .c-tag-box {
            padding-bottom: 8px;
            margin-bottom: 16px;
            border-bottom: 1px solid $color-gray-12;
            .c-tag--selected {
                color: $color-amazon-bluegreen;
                border-color: $color-amazon-bluegreen;
                font-weight: bold;
            }
            .c-badge-new {
                background: $color-amazon-bluegreen;
            }
        }
    }
    &.m_nav--expand {
        li {
            a {
                padding: 2px 8px;
            }
        }
    }
}
@keyframes tooltip-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes tooltip-out {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
body.tabEditType {
    // 신규 GNB 적용
    .header-relative {
        .c-magnet {
            z-index: 102 !important;
        }
    }
    header {
        position: relative;
        z-index: 103;

        &.header {
            position: fixed;
            z-index: 102;
        }

        & + .c-magnet {
            z-index: 102 !important;
        }
    }
    .main .c-magnet.c-magnet--fixed.is--active {
        z-index: 1 !important;
    }
}

.main {
    overflow: hidden;
    width: 100%;
    background: #eee;

    article {
        overflow: hidden;
        box-sizing: border-box;
        background: #eee;
        // transform: translateZ(0);
    }

    & [data-status="init"] {
        article {
            &:nth-of-type(1) li:nth-of-type(n + 15),
            &:nth-of-type(3) li:nth-of-type(n + 15) {
                display: none;
            }
        }
    }
    &#sub {
        min-height: calc(100vh - 57px); // 브라우저 높이 - 헤더 높이
    }
}

.main_init {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
}

.main-pagination {
    @include size(132px 32px);

    margin: 16px auto 25px;
    position: relative;
    background: transparent;

    &__indicator {
        line-height: 32px;
        text-align: center;
        color: #333;
    }

    &__number {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: -1px;

        span {
            color: $color-gray-04;
            font-weight: normal;
        }
    }

    button {
        overflow: hidden;
        position: absolute;
        top: 0;

        @include size(32px);

        border: 1px solid #d1d1d6;
        border-radius: 4px;
        background: #fff;
        text-indent: -99999px;

        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;

            @include size(8px);

            margin: -5px 0 0 -5px;
            border: 1px solid $color-gray-06;
            transform: rotate(45deg);
        }

        &.main-pagination__prev {
            left: 0;

            &:after {
                border-width: 0 0 1px 1px;
                margin-left: -4px;
            }
        }

        &.main-pagination__next {
            right: 0;

            &:after {
                border-width: 1px 1px 0 0;
                margin-left: -8px;
            }
        }
    }
}

.swiper-container .swiper-slide,
.swiper-wrapper {
    transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.swiper-slide-preload {
    position: absolute;
    top: 0;
    left: 0;
}

.swiper-lazy[data-src] {
    visibility: hidden;
    height: 0;
}

.swiper-lazy-loaded[data-src] {
    visibility: visible;
    height: 100%;
}

.header-relative {
    padding-top: 0;
    .c-magnet {
        z-index: 101 !important;
        .m_nav {
            .nav {
                margin-right: 0;
            }
        }
    }
}

.carrier-list {
    .l-grid:first-child {
        margin-top: 0 !important;
    }
}
