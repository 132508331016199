[data-type="Billboard_Blurry"] {
    .c-billboard {
        overflow: hidden;
        position: relative;
        padding-top: 57px;
        background-size: 150%;
        background-position: center;
        transition: all 0.2s linear;
        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        &:before {
            background-color: rgba($color-gray-03, 0.3);
            backdrop-filter: blur(80px);
        }
        &:after {
            background: linear-gradient(180deg, rgba($color-gray-14, 0) 0%, rgba($color-gray-14, 1) 100%);
        }
        &__banner {
            padding: 0 16px 26px;
            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 16px;
                bottom: 26px;
                left: 16px;
                border-radius: 16px;
                background-color: rgba($color-gray-01, 0.1);
            }
        }
        &__bg {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: scale(2);
            background-position: center;
            background-size: cover;
            transition: all 0.2s linear;
            filter: blur(10px);
        }
        &__info {
            overflow: hidden;
            position: absolute;
            left: 48px;
            bottom: 90px;
            right: 48px;
            z-index: 10;
            color: $color-gray-14;
            text-align: center;
        }
        &__title {
            overflow: hidden;
            max-height: 68px;
            font-size: 28px;
            line-height: 34px;
            font-weight: 600;
            font-family: $font-outfit;
            word-break: keep-all;
            word-wrap: break-word;
        }
        &__desc {
            height: 19px;
            margin-top: 10px;
            font-size: 15px;
            font-weight: 400;
        }
    }
    .c-pagination {
        position: absolute;
        left: 0;
        bottom: 56px;
        right: 0;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: $color-gray-14;
        font-family: $font-outfit;
        &__bar {
            display: block;
            width: 120px;
            height: 2px;
            &,
            &::-webkit-progress-bar {
                background-color: rgba($color-gray-14, 0.2);
            }
            &::-webkit-progress-value {
                background-color: $color-gray-14;
            }
            &--animated::-webkit-progress-value {
                transition: width 0.5s linear;
            }
        }
        &__current {
            margin-left: 4px;
        }
        &__total {
            display: flex;
            align-items: center;
            font-weight: 700;
            opacity: 0.6;
            &:before {
                content: "";
                display: inline-block;
                width: 2px;
                height: 9px;
                margin: 0 5px;
                background-color: $color-gray-14;
            }
        }
        &__link {
            display: flex;
            align-items: center;
            position: relative;
            padding-right: 16px;
            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                margin: -1px 0 0 -4px;
                width: 9px;
                height: 2px;
                background-color: $color-gray-14;
            }
            &:after {
                transform: rotate(90deg);
            }
        }
    }
    // 타이틀 애니메이션 적용
    .animated {
        .c-billboard__info {
            animation: BillboardTitleUP 1.2s cubic-bezier(0.23, 1, 0.32, 1);
        }
    }
    .c-lazyload {
        border-radius: 16px;
        box-shadow: 0 6px 14px 0 rgba($color-gray-01, 0.1);
        background-color: #f5f6fa;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(360deg, rgba($color-gray-01, 0.2) 0%, rgba($color-gray-01, 0) 80%);
        }
        img {
            object-fit: cover;
        }
    }
}

@keyframes BillboardTitleUP {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
